.modalContainer {
  position: fixed;
  top: 0;
  margin-top: 150px;
  left: 0;
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal {
  position: relative;
  width: 600px;
  height: 100%; 
  transition: all 0.5s ease-in;
  border-radius: 9px;
}

.hidden {
  pointer-events: none;
}


@media (min-width: 769px) {
  .modalContainer {
    flex-direction: row;
  }
  .modal {
    width: 50%; /* Adjust this as per requirement */
    height: 100%;
  }
}

@media (max-width: 768px) {
  .modalContainer {
    height: 100%; /* Adjust the height to full in smaller screens */
    margin-top: 0px;
  }
  .modal {
    width: 100%;
    height: 50%; /* Change the height to half in smaller screens */
    transition: all 0.5s ease-in;
    border-radius: 9px;
    max-width: 600px;
    margin: auto;
  }
}

.modalLeft {
  background-color: #ffffff;
  transform: translateX(-200%);
}

.modalRight {
  background-color: #7e009b;
  transform: translateX(200%);
}

.modalLeft.active,
.modalRight.active {
  transform: translateX(0%);
}

.buttonContainer {
  position: sticky;
  z-index: 20;
  top: 75%;
  left: 20px;
  height: 100%;
}

@media (max-height: 765px) {
  .hide-on-small-height {
    display: none;
  }
}
