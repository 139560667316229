@keyframes glowing {
    0% {
      text-shadow: 0 0 5px #ff9933, 0 0 10px #ff9933, 0 0 15px #ff6600, 0 0 20px #ff6600, 0 0 25px #ff6600, 0 0 30px #ff6600, 0 0 35px #ff6600;
    }
    50% {
      text-shadow: 0 0 10px #ff9933, 0 0 20px #ff9933, 0 0 30px #ff6600, 0 0 40px #ff6600, 0 0 50px #ff6600, 0 0 60px #ff6600, 0 0 70px #ff6600;
    }
    100% {
      text-shadow: 0 0 5px #ff9933, 0 0 10px #ff9933, 0 0 15px #ff6600, 0 0 20px #ff6600, 0 0 25px #ff6600, 0 0 30px #ff6600, 0 0 35px #ff6600;
    }
  }
  .glowing-text {
    animation: glowing 1.5s infinite alternate;
  }