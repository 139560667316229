@keyframes borderColorChange {
  0% {
    border-color: #1D4ED8; /* blue-500 */
  }
  33% {
    border-color: #EC4899; /* pink-500 */
  }
  66% {
    border-color: #10B981; /* green-500 */
  }
  100% {
    border-color: #1D4ED8; /* blue-500 */
  }
}

.animate-border-color {
  animation: borderColorChange 4s linear infinite;
}